import VoxeetSDK from "@voxeet/voxeet-web-sdk";

export const VOXEET_SESSION_LOADING = "VOXEET_SESSION_LOADING";
export const VOXEET_SESSION_CONNNECTED = "VOXEET_SESSION_CONNNECTED";
export const VOXEET_SESSION_LOADING_STOPED = "VOXEET_SESSION_LOADING_STOPED";
export const VOXEET_LOADING = "VOXEET_LOADING";
export const VOXEET_CONNECTED = "VOXEET_CONNECTED";
export const VOXEET_LEAVED = "VOXEET_LEAVED";
export const VOXEET_ERROR = "VOXEET_ERROR";
export const VOXEET_CLEAR_ERROR = "VOXEET_CLEAR_ERROR";
export const VOXEET_AUTOPLAY_BLOCKED_ENABLED = "VOXEET_AUTOPLAY_BLOCKED_ENABLED";
export const VOXEET_AUTOPLAY_BLOCKED_DISABLED = "VOXEET_AUTOPLAY_BLOCKED_DISABLED";

export const initializeAndOpenSession = (accessToken, refreshTokenCallback) => {
  return async (dispatch) => {
    if (!VoxeetSDK.session.isOpen()) {
      dispatch(sessionIsLoading());

      try {
        VoxeetSDK.conference.on("autoplayBlocked", () => {
          dispatch(setAutoPlayBlockedEnabled());
        });

        VoxeetSDK.initializeToken(accessToken, refreshTokenCallback);
        await VoxeetSDK.session.open({ name: "Listener" });

        dispatch(sessionConnected());
      } catch (e) {
        console.error("Error on open session2:", e);

        dispatch(sessionLoadingStoped());
      }
    } else {
      dispatch(sessionLoadingStoped());
    }
  };
};

export const createAndListenConference = (conferenceAlias) => {
  return async (dispatch) => {
    dispatch(isLoading());

    try {
      const dolbyConference = await VoxeetSDK.conference.create({
        alias: conferenceAlias,
        params: {
          dolbyVoice: true,
          liveRecording: true,
        },
      });
      await VoxeetSDK.conference.listen(dolbyConference);

      setTimeout(() => {
        dispatch(connected());
      }, 500);
    } catch (e) {
      console.error("Error on joining SDK conference:", e);

      dispatch(onError(e));
    }
  };
};

export const leaveConference = (shouldCloseSession = false) => {
  return async (dispatch) => {
    try {
      dispatch(isLoading());
      if (shouldCloseSession) {
        dispatch(sessionIsLoading());
      }

      VoxeetSDK.conference.removeAllListeners();
      await VoxeetSDK.conference.leave();

      if (shouldCloseSession) {
        await VoxeetSDK.session.close();

        dispatch(sessionLoadingStoped());
      }

      dispatch(leaved());
    } catch (e) {
      console.error("Error on leaving SDK conferance:", e);

      dispatch(onError(e));
      if (shouldCloseSession) {
        dispatch(sessionLoadingStoped());
      }
    }
  };
};

export const playBlockedAudio = () => {
  return async (dispatch) => {
    await VoxeetSDK.conference.playBlockedAudio();

    dispatch(setAutoPlayBlockedDisabled());
  };
};

export const sessionIsLoading = () => ({
  type: VOXEET_SESSION_LOADING,
});

export const sessionConnected = () => ({
  type: VOXEET_SESSION_CONNNECTED,
});

export const sessionLoadingStoped = () => ({
  type: VOXEET_SESSION_LOADING_STOPED,
});

export const isLoading = () => ({
  type: VOXEET_LOADING,
});

export const connected = () => ({
  type: VOXEET_CONNECTED,
});

export const leaved = () => ({
  type: VOXEET_LEAVED,
});

export const onError = (error) => ({
  type: VOXEET_ERROR,
  payload: error,
});

export const clearError = () => ({
  type: VOXEET_CLEAR_ERROR,
});

export const setAutoPlayBlockedEnabled = () => ({
  type: VOXEET_AUTOPLAY_BLOCKED_ENABLED,
});

export const setAutoPlayBlockedDisabled = () => ({
  type: VOXEET_AUTOPLAY_BLOCKED_DISABLED,
});
