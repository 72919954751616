import { Suspense, lazy } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Loading from "./Loading";

const HomeLazy = lazy(() => import("./Home"));

const Home = () => {
  const isMobile = useMediaQuery("(max-width: 800px)");

  return (
    <Suspense fallback={<Loading isMobile={isMobile} />}>
      <HomeLazy />
    </Suspense>
  );
};

export default Home;
