import { SET_LANGUAGE } from "state/actions/language";

const initialState = null;

const language = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
};

export default language;
