import {
  VOXEET2_SESSION_LOADING,
  VOXEET2_SESSION_CONNNECTED,
  VOXEET2_SESSION_LOADING_STOPED,
  VOXEET2_LOADING,
  VOXEET2_CONNECTED,
  VOXEET2_ERROR,
  VOXEET2_LEAVED,
  VOXEET2_CLEAR_ERROR,
  VOXEET2_AUTOPLAY_BLOCKED_ENABLED,
  VOXEET2_AUTOPLAY_BLOCKED_DISABLED,
} from "state/actions/voxeet2";

const initialState = {
  sdk2sessionLoading: false,
  sdk2sessionConnected: false,
  sdk2loading: false,
  sdk2isConnected: false,
  sdk2error: null,
  sdk2autoPlayBlocked: false,
};

const voxeet2 = (state = initialState, action) => {
  switch (action.type) {
    case VOXEET2_SESSION_LOADING:
      return {
        ...state,
        sdk2sessionLoading: true,
      };

    case VOXEET2_SESSION_CONNNECTED:
      return {
        ...state,
        sdk2sessionLoading: false,
        sdk2sessionConnected: true,
      };

    case VOXEET2_SESSION_LOADING_STOPED:
      return {
        ...state,
        sdk2sessionLoading: false,
      };

    case VOXEET2_LOADING:
      return {
        ...state,
        sdk2loading: true,
      };

    case VOXEET2_CONNECTED:
      return {
        ...state,
        sdk2loading: false,
        sdk2isConnected: true,
      };

    case VOXEET2_LEAVED:
      return {
        ...state,
        sdk2loading: false,
        sdk2isConnected: false,
        sdk2autoPlayBlocked: false,
      };

    case VOXEET2_ERROR:
      return {
        ...state,
        sdk2loading: false,
        sdk2error: action.payload,
      };

    case VOXEET2_CLEAR_ERROR:
      return {
        ...state,
        sdk2error: null,
      };

    case VOXEET2_AUTOPLAY_BLOCKED_ENABLED:
      return {
        ...state,
        sdk2autoPlayBlocked: true,
      };

    case VOXEET2_AUTOPLAY_BLOCKED_DISABLED:
      return {
        ...state,
        sdk2autoPlayBlocked: false,
      };

    default:
      return state;
  }
};

export default voxeet2;
