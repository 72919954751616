import Divider from "@material-ui/core/Divider";
import { Skeleton } from "@material-ui/lab";

import styles from "./Loading.module.css";

const Loading = ({ isMobile }) => {
  return isMobile ? (
    <div className={styles.mobileContainer}>
      <div className={styles.middle}>
        <Skeleton variant="rect" animation="wave" height={"60%"} />
        <Skeleton variant="rect" animation="wave" className={styles.roundedBorders} height={"2rem"} width={"40%"} />
        <Divider />
        <Skeleton variant="rect" animation="wave" className={styles.roundedBorders} height={"2rem"} width={"20%"} />
      </div>
      <div className={styles.footer}>
        <Divider />
        <Skeleton variant="rect" animation="wave" className={styles.roundedBorders} height={"2rem"} width={"30%"} />
      </div>
    </div>
  ) : (
    <div className={styles.mainContainer}>
      <Skeleton variant="rect" animation="wave" height={"100%"} width={"25%"} />
      <div className={styles.center}>
        <Skeleton variant="rect" animation="wave" className={styles.selectAnotherLanguage} height={"2rem"} />
      </div>
      <Skeleton variant="rect" animation="wave" height={"100%"} width={"30%"} />
    </div>
  );
};

export default Loading;
