const APP_BASE_URL = "";
export const APP_HOME_URL = `${APP_BASE_URL}/`;
export const APP_LANGUAGES_URL = `${APP_BASE_URL}/language`;

export const getListenerURL = () => `${process.env.REACT_APP_API_ENDPOINT}/listeners/auth-token`;

export const CONFERENCE_TOKEN_KEY = "__listener-app-token__";

export const REQUEST_OPTIONS = {
  method: "GET",
  mode: "cors",
  cache: "no-cache",
  credentials: "omit",
  body: null,
};

export const JOIN_SESSION = "JoinSession";
export const CLOSE_SESSION = "CloseSession";

export const COLLAPSED_TEXT_LENGTH = 40;

export const CHAT_EVENTS = {
  JoinChat: "JoinChat",
  LeaveChat: "LeaveChat",
  SendChatMessage: "SendChatMessage",
  ReceiveMessage: "ReceiveMessage",
};
