import React from "react";
import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider } from "@material-ui/core";

const theme = responsiveFontSizes(createTheme({}));

const CustomThemeProvider = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);

export default CustomThemeProvider;
