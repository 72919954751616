import {
  VOXEET_SESSION_LOADING,
  VOXEET_SESSION_CONNNECTED,
  VOXEET_SESSION_LOADING_STOPED,
  VOXEET_LOADING,
  VOXEET_CONNECTED,
  VOXEET_ERROR,
  VOXEET_LEAVED,
  VOXEET_CLEAR_ERROR,
  VOXEET_AUTOPLAY_BLOCKED_ENABLED,
  VOXEET_AUTOPLAY_BLOCKED_DISABLED,
} from "state/actions/voxeet";

const initialState = {
  sessionLoading: false,
  sessionConnected: false,
  loading: false,
  isConnected: false,
  error: null,
  autoPlayBlocked: false,
};

const voxeet = (state = initialState, action) => {
  switch (action.type) {
    case VOXEET_SESSION_LOADING:
      return {
        ...state,
        sessionLoading: true,
      };
    case VOXEET_SESSION_CONNNECTED:
      return {
        ...state,
        sessionLoading: false,
        sessionConnected: true,
      };

    case VOXEET_SESSION_LOADING_STOPED:
      return {
        ...state,
        sessionLoading: false,
      };

    case VOXEET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case VOXEET_CONNECTED:
      return {
        ...state,
        loading: false,
        isConnected: true,
      };

    case VOXEET_LEAVED:
      return {
        ...state,
        loading: false,
        isConnected: false,
        autoPlayBlocked: false,
      };

    case VOXEET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case VOXEET_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    case VOXEET_AUTOPLAY_BLOCKED_ENABLED:
      return {
        ...state,
        autoPlayBlocked: true,
      };

    case VOXEET_AUTOPLAY_BLOCKED_DISABLED:
      return {
        ...state,
        autoPlayBlocked: false,
      };

    default:
      return state;
  }
};

export default voxeet;
