import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";

import voxeet from "state/reducers/voxeet";
import voxeet2 from "state/reducers/voxeet2";
import language from "state/reducers/language";
import conference from "state/reducers/conference";

const rootReducer = combineReducers({
  voxeet,
  voxeet2,
  language,
  conference,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunkMiddleware)));

export default store;
