import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { APP_HOME_URL, APP_LANGUAGES_URL } from "common/constants";

import Home from "pages/Home";
import Languages from "pages/Languages";
import NotFound from "pages/NotFound";

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path={APP_LANGUAGES_URL}>
        <Languages />
      </Route>
      <Route exact path={APP_HOME_URL}>
        <Home />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  </Router>
);

export default Routes;
