import Divider from "@material-ui/core/Divider";
import { Skeleton } from "@material-ui/lab";

import styles from "./Loading.module.css";

const Loading = () => {
  return (
    <div className={styles.container}>
      <Skeleton variant="rect" animation="wave" height={"20%"} width={"100%"} />
      <div className={styles.textContent}>
        <Skeleton variant="rect" animation="wave" className={styles.roundedBorders} height={"2rem"} width={"25%"} />
        <Skeleton variant="rect" animation="wave" className={styles.roundedBorders} height={"2rem"} width={"15%"} />
        <Skeleton variant="rect" animation="wave" className={styles.roundedBorders} height={"4rem"} width={"35%"} />
        <div className={styles.languages}>
          <Skeleton variant="rect" animation="wave" className={styles.roundedBorders} height={"2rem"} width={"20%"} />
          <Skeleton variant="rect" animation="wave" className={styles.roundedBorders} height={"2rem"} width={"20%"} />
          <Skeleton variant="rect" animation="wave" className={styles.roundedBorders} height={"2rem"} width={"20%"} />
          <Skeleton variant="rect" animation="wave" className={styles.roundedBorders} height={"2rem"} width={"20%"} />
        </div>
      </div>
      <Divider />
      <Skeleton variant="rect" animation="wave" className={styles.roundedBorders} height={"4rem"} width={"25%"} />
    </div>
  );
};

export default Loading;
