import { SET_CONFERENCE } from "state/actions/conference";

const initialState = null;

const conference = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFERENCE:
      return action.payload;
    default:
      return state;
  }
};

export default conference;
