import React, { Suspense, lazy } from "react";

const NotFoundLazy = lazy(() => import("./NotFound"));

const NotFound = () => (
  <Suspense fallback={null}>
    <NotFoundLazy />
  </Suspense>
);

export default NotFound;
