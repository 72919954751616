import { Suspense, lazy } from "react";

import Loading from "./Loading";

const LanguagesLazy = lazy(() => import("./Languages"));

const Languages = () => (
  <Suspense fallback={<Loading />}>
    <LanguagesLazy />
  </Suspense>
);

export default Languages;
