import VoxeetSDK2 from "common/sdks/dolby";

export const VOXEET2_SESSION_LOADING = "VOXEET2_SESSION_LOADING";
export const VOXEET2_SESSION_CONNNECTED = "VOXEET2_SESSION_CONNNECTED";
export const VOXEET2_SESSION_LOADING_STOPED = "VOXEET2_SESSION_LOADING_STOPED";
export const VOXEET2_LOADING = "VOXEET2_LOADING";
export const VOXEET2_CONNECTED = "VOXEET2_CONNECTED";
export const VOXEET2_LEAVED = "VOXEET2_LEAVED";
export const VOXEET2_ERROR = "VOXEET2_ERROR";
export const VOXEET2_CLEAR_ERROR = "VOXEET2_CLEAR_ERROR";
export const VOXEET2_AUTOPLAY_BLOCKED_ENABLED = "VOXEET2_AUTOPLAY_BLOCKED_ENABLED";
export const VOXEET2_AUTOPLAY_BLOCKED_DISABLED = "VOXEET2_AUTOPLAY_BLOCKED_DISABLED";

export const SDK2InitializeAndOpenSession = (accessToken, refreshTokenCallback) => {
  return async (dispatch) => {
    if (!VoxeetSDK2.session.isOpen()) {
      dispatch(sessionIsLoading());

      try {
        VoxeetSDK2.conference.on("autoplayBlocked", () => {
          dispatch(setAutoPlayBlockedEnabled());
        });

        VoxeetSDK2.initializeToken(accessToken, refreshTokenCallback);
        await VoxeetSDK2.session.open({ name: "Listener" });

        dispatch(sessionConnected());
      } catch (e) {
        console.error("Error on open session", e);

        dispatch(sessionLoadingStoped());
      }
    } else {
      dispatch(sessionLoadingStoped());
    }
  };
};

export const SDK2createAndListenConference = (conferenceAlias) => {
  return async (dispatch) => {
    dispatch(isLoading());
    try {
      const dolbySpeakersConference = await VoxeetSDK2.conference.create({
        alias: conferenceAlias,
        params: {
          dolbyVoice: true,
          liveRecording: true,
        },
      });

      await VoxeetSDK2.conference.listen(dolbySpeakersConference);

      dispatch(connected());
    } catch (e) {
      console.error("Error on joining SDK2 conference:", e);

      dispatch(onError(e));
    }
  };
};

export const SDK2LeaveConference = (shouldCloseSession = false) => {
  return async (dispatch) => {
    try {
      dispatch(isLoading());
      if (shouldCloseSession) {
        dispatch(sessionIsLoading());
      }

      VoxeetSDK2.conference.removeAllListeners();
      await VoxeetSDK2.conference.leave();

      if (shouldCloseSession) {
        await VoxeetSDK2.session.close();

        dispatch(sessionLoadingStoped());
      }

      dispatch(leaved());
    } catch (e) {
      console.error("Error on leaving SDK2 conferance:", e);

      dispatch(onError(e));
      if (shouldCloseSession) {
        dispatch(sessionLoadingStoped());
      }
    }
  };
};

export const SDK2PlayBlockedAudio = () => {
  return async (dispatch) => {
    await VoxeetSDK2.conference.playBlockedAudio();

    dispatch(setAutoPlayBlockedDisabled());
  };
};

export const sessionIsLoading = () => ({
  type: VOXEET2_SESSION_LOADING,
});

export const sessionConnected = () => ({
  type: VOXEET2_SESSION_CONNNECTED,
});

export const sessionLoadingStoped = () => ({
  type: VOXEET2_SESSION_LOADING_STOPED,
});

export const isLoading = () => ({
  type: VOXEET2_LOADING,
});

export const connected = () => ({
  type: VOXEET2_CONNECTED,
});

export const leaved = () => ({
  type: VOXEET2_LEAVED,
});

export const onError = (error) => ({
  type: VOXEET2_ERROR,
  payload: error,
});

export const clearError = () => ({
  type: VOXEET2_CLEAR_ERROR,
});

export const setAutoPlayBlockedEnabled = () => ({
  type: VOXEET2_AUTOPLAY_BLOCKED_ENABLED,
});

export const setAutoPlayBlockedDisabled = () => ({
  type: VOXEET2_AUTOPLAY_BLOCKED_DISABLED,
});
