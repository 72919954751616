import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import CustomThemeProvider from "common/theme";
import store from "state/store";
import Routes from "common/routes";

import "index.css";

ReactDOM.render(
  <CustomThemeProvider>
    <Provider store={store}>
      <Routes />
    </Provider>
  </CustomThemeProvider>,
  document.getElementById("root")
);
